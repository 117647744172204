export default {
  'Am I clean today?': '',
  'How have I acted differently?': '',
  'Did my disease run my life today?': '',
  'If so, how?': '',
  'What did I do today that I wish I had not done?': '',
  'What have I left undone that I wish I had done?': '',
  'Was I good to myself today?': '',
  'How?': '',
  'Was today a good day?': '',
  'Was I happy?': '',
  'Was I serene?': '',
  'Did I talk to my sponsor today?': '',
  'Did I attend a meeting today?': '',
  'Where?': '',
  'Did I share my experiences, strengths, and hopes?': '',
  'Who are the people in my life that I trust today?': '',
  'Who has trust in me today?': '',

  'Did I read from our literature today?': '',
  'What steps did I consciously work?': '',
  'Did I admit my powerlessness today?': '',
  'Was I able to put my trust in my Higher Power today?': '',
  'What did I learn about myself today?': '',
  'Did I make any amends today?': '',
  'Do I owe any?': '',
  'Did I admit fault to anyone today?': '',
  'Did I worry about yesterday or tomorrow?': '',
  'Can I accept myself as I am today?': '',
  'Did I feel like I was a part of humanity today?': '',
  'Did I allow myself to become obsessed by anything today?': '',
  'What has God given me to be grateful for today?': '',
  'Have I done anything to cause harm to myself or to another today?': '',
  'If so, what?': '',
  'Am I willing to change today?': '',
  'Did I pray or meditate today?': '',
  'How did this affect my life?': '',
  'What spiritual principles have I been able to practice in my life today?': '',
  'Was the most important thing in my life today staying clean?': '',
  'Have I given of myself today without expecting anything in return?': '',
  'Was there fear in my life today?': '',
  'Did I feel intense joy or pain?': '',
  'Did I call or visit someone in the program today?': '',
  'Did I pray for the well-being of another today?': '',
  'Was I happy today?': '',
  'Have I been peaceful today?': '',
  'Did I consciously remember that I have a choice today?': '',
  Notes: '',
};
